/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {},
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // initialize animations
        AOS.init();

        function toggleHeader() {
          const header = document.getElementById("myHeader");
          if (window.scrollY > 0) {
            header.classList.add("scrolled");
          } else {
            header.classList.remove("scrolled");
          }
        }

        window.addEventListener("scroll", function () {
          toggleHeader();
        });
        $(document).ready(function () {
          toggleHeader();
        });

        const header = document.querySelector('#myHeader');
        document.querySelector('.navbar-toggler').addEventListener('click', function () {
          header.classList.toggle('show');
        });

        /* START SELECT 2! */
        function formatState(state) {
          if (!state.id || !$(state.element).attr("data-iconurl")) {
            return state.text;
          }
          var iconUrl = $(state.element).attr("data-iconurl");
          var baseUrl = iconUrl;
          var $state = $(
            '<span><img src="' +
            baseUrl +
            '" class="img-flag" width="22px" style="margin-right: 20px;"/> ' +
            state.text +
            "</span>"
          );
          return $state;
        }

        $(".js-select2")
          .select2({
            minimumResultsForSearch: Infinity,
            templateResult: formatState,
          })
          .on("select2:open", function (e) {
            setTimeout(function () {
              $(".select2-dropdown").addClass("opened");
            }, 200);
          })
          .on("select2:closing", function (e) {
            // if removed, for some examples, the Select2 will not highlight the selected element
            $(".select2-dropdown").removeClass("opened");
          });
        /* END SELECT 2! */

        /* GRAVITY FORMS */
        if ($(".ginput_container_fileupload").length > 0) {
          $('<i class="attachment"></i>').insertAfter(
            ".ginput_container_fileupload input[type=file]"
          );
          $(".ginput_container_fileupload i.attachment").on(
            "click",
            function () {
              $(this).prev().trigger("click");
            }
          );
        }

        $(".ginput_container_select select").select2();

        $(document).on("gform_post_render", function () {
          $(".ginput_container_select select").select2();
        });
        /* END GRAVITY FORMS */

        /* SEARCH */
        $("a.search").click(function () {
          $(".search-wrapper").toggleClass("open");
        });

        $("a.popup-close").click(function () {
          $(".search-wrapper").removeClass("open");
        });

        // close opened popup when escape key is pressed
        document.addEventListener("keydown", function (event) {
          if (event.which == 27) {
            $(".search-wrapper a.popup-close").trigger("click");
          }
        });
        /* END SEARCH */

        // set "Definições de Cookies" class and href attributes
        $(document).ready(function () {
          $("li.cookie-menu a").attr("class", "js-rgc-open-cookies-settings").attr("href", "javascript:void(0)");
        });

        /* FLOATING BAR */
        function checkScrollPos() {
          var pos = $(document).height() - $(window).height() - $("footer").innerHeight();
          if ($(this).scrollTop() > pos) {
            $(".floating-bar a.link").fadeOut(100);
          } else {
            if ($(".floating-bar .form-wrapper").css("display") != "block") {
              $(".floating-bar a.link").fadeIn(100);
            }
          }
        }
        $(window).scroll(function () {
          checkScrollPos();
        });
        $(document).ready(function () {
          checkScrollPos();
        });

        $(".floating-bar a.link").click(function () {
          $(this).fadeOut(100, function () {
            $(".floating-bar .form-wrapper").fadeIn();
          });
        });

        $(".floating-bar a.close").click(function () {
          var pos = $(document).height() - $(window).height() - $("footer").innerHeight();
          $(".floating-bar .form-wrapper").fadeOut(100, function () {
            checkScrollPos();
          });
        });

        // close opened popup when escape key is pressed
        document.addEventListener("keydown", function (event) {
          if (event.which == 27) {
            $(".floating-bar a.close").trigger("click");
          }
        });

        function cleanOptions(select) {
          var first = select.find(" option:eq(0)");
          $(select).empty().append(first);
        }

        function matchStart(params, data) {
          // If there are no search terms, return all of the data
          if ($.trim(params.term) === "") {
            return data;
          }

          // Do not display the item if there is no 'text' property
          if (typeof data.text === "undefined") {
            return null;
          }

          // `params.term` should be the term that is used for searching
          // `data.text` is the text that is displayed for the data object
          if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
            var modifiedData = $.extend({}, data, true);
            //modifiedData.text += ' (matched)';

            // You can return modified objects from here
            // This includes matching the `children` how you want in nested data sets
            return modifiedData;
          }

          // `data.children` contains the actual options that we are matching against
          var filteredChildren = [];
          $.each(data.children, function (idx, child) {
            if (
              child.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1
            ) {
              filteredChildren.push(child);
            }
          });

          // If we matched any of the timezone group's children, then set the matched children on the group
          // and return the group object
          if (filteredChildren.length) {
            var modifiedData = $.extend({}, data, true);
            modifiedData.children = filteredChildren;

            // You can return modified objects from here
            // This includes matching the `children` how you want in nested data sets
            return modifiedData;
          }

          // Return `null` if the term should not be displayed
          return null;
        }

        function getDealerships() {
          var district = $("#gform_3 div.districts select").val();
          cleanOptions($("#gform_3 div.dealerships select"));

          var data = {
            action: "get_dealerships",
            post_type: "POST",
            district: district,
          };

          $.post(ajaxurl, data, function (response) {
            $("#gform_3 div.dealerships select").append(response);
            setTimeout(function () {
              $(".ginput_container_select select").select2({
                matcher: matchStart,
              });
            }, 500);
          });
        }

        $(document).on("change", "#gform_3 div.districts select", function () {
          getDealerships();
        });
        $(document).on("gform_post_render", function () {
          getDealerships();
        });

        $("select[name='input_12']").change(function () {
          var sel = $(this).val();
          checkTypes(sel);
        });
        /* END FLOATING BAR */
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        const bannerSwiper = new Swiper(".swiper-hero", {
          loop: true,
          pagination: {
            el: ".swiper-hero .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-hero .swiper-button-next",
            prevEl: ".swiper-hero .swiper-button-prev",
          },
        });
      },
    },
    // About us page, note the change from about-us to about_us.
    page_template_tpl_faqs: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        /*$('.question').click(function() {
          $(this).toggleClass('active').find('.answer').slideToggle();
          if($(this).hasClass("active")) {
            $(this).find('.icon-container .icon-plus').hide();
            $(this).find('.icon-container .icon-minus').fadeIn();
          } else {
            $(this).find('.icon-container .icon-plus').fadeIn();
            $(this).find('.icon-container .icon-minus').hide();
          }
        });*/
        var paged = 1;

        function filterFAQs() {
          var category = $(".categories a.tag.active").data("slug");

          var data = {
            action: "get_faqs",
            post_type: "POST",
            category: category,
          };

          $.post(ajaxurl, data, function (response) {
            var spl = response.split(";;");
            $("#div_results").html(spl[1]);
            if (!parseInt(spl[0])) {
              $("a.loadmore").fadeOut();
            } else {
              $("a.loadmore").fadeIn();
            }
            paged = 1;
          });
        }

        $(".categories a.tag").click(function () {
          $(".categories a.tag").removeClass("active");
          $(this).addClass("active");
          filterFAQs();
        });

        var posts_per_page = 10;
        $("body").on("click", "a.loadmore", function () {
          var category = $(".categories a.tag.active").data("slug");
          var total = $(this).data("total");

          var data = {
            action: "get_more_faqs",
            post_type: "POST",
            category: category,
            paged: paged,
          };

          $.post(ajaxurl, data, function (response) {
            $("#div_results_posts").append(response);
            paged++;
            if (paged * posts_per_page >= total) {
              $("a.loadmore").fadeOut();
            }
          });
        });
      },
    },
    // Manual
    page_template_tpl_manual: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(window).scroll(function () {
          if ($(window).width() > 1024) {
            var elem = $(".areas-wrapper").offset().top - 200;
            if ($(window).scrollTop() >= elem) {
              $(".menu-wrapper").addClass("is-sticky");
            } else {
              $(".menu-wrapper").removeClass("is-sticky");
            }
          }
        });
      },
    },
    // Contacts
    page_template_tpl_contacts: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        function cleanOptions(select) {
          var first = select.find(" option:eq(0)");
          $(select).empty().append(first);
        }

        function matchStart(params, data) {
          // If there are no search terms, return all of the data
          if ($.trim(params.term) === "") {
            return data;
          }

          // Do not display the item if there is no 'text' property
          if (typeof data.text === "undefined") {
            return null;
          }

          // `params.term` should be the term that is used for searching
          // `data.text` is the text that is displayed for the data object
          if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
            var modifiedData = $.extend({}, data, true);
            //modifiedData.text += ' (matched)';

            // You can return modified objects from here
            // This includes matching the `children` how you want in nested data sets
            return modifiedData;
          }

          // `data.children` contains the actual options that we are matching against
          var filteredChildren = [];
          $.each(data.children, function (idx, child) {
            if (
              child.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1
            ) {
              filteredChildren.push(child);
            }
          });

          // If we matched any of the timezone group's children, then set the matched children on the group
          // and return the group object
          if (filteredChildren.length) {
            var modifiedData = $.extend({}, data, true);
            modifiedData.children = filteredChildren;

            // You can return modified objects from here
            // This includes matching the `children` how you want in nested data sets
            return modifiedData;
          }

          // Return `null` if the term should not be displayed
          return null;
        }

        function getDealerships() {
          var district = $("#gform_1 div.districts select").val();
          cleanOptions($("#gform_1 div.dealerships select"));

          var data = {
            action: "get_dealerships",
            post_type: "POST",
            district: district,
          };

          $.post(ajaxurl, data, function (response) {
            $("#gform_1 div.dealerships select").append(response);
            setTimeout(function () {
              $(".ginput_container_select select").select2({
                matcher: matchStart,
              });
            }, 500);
          });
        }

        $(document).on("change", "#gform_1 div.districts select", function () {
          getDealerships();
        });
        $(document).on("gform_post_render", function () {
          getDealerships();
        });

        /*if(location.href.indexOf("?t=") !== -1) {
          var spl = location.href.split("?t=");
          if(spl) {
            if(spl[1] == "particular") {
              $("#gform_1 #choice_1_8_0").prop("checked", true);
            } else {
              $("#gform_1 #choice_1_8_1").prop("checked", true);
            }
            history.replaceState(null, "contacts", spl[0]);
          }
        }*/
        
        if ($("#package").length > 0) {
          var selected = $("#package").val();
          var package = "";

          if (selected == "home") package = "Home Blue";
          if (selected == "building") package = "Building Blue";
          if (selected == "business") package = "Business Blue";

          $("select[name='input_12']").val(package);
          checkTypes(package);
        }

        $("select[name='input_12']").change(function () {
          var sel = $(this).val();
          checkTypes(sel);
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function checkTypes(value) {
    if (value == "Home Blue") {
      $("input[name='input_13']").val("F67"); // form type
      $("input[name='input_14']").val("TP10"); // tipo de lead
    } else {
      if (value == "Building Blue") {
        $("input[name='input_13']").val("F68"); // form type
        $("input[name='input_14']").val("TP10"); // tipo de lead
      } else {
        if (value == "Business Blue") {
          $("input[name='input_13']").val("F66"); // form type
          $("input[name='input_14']").val("TP11"); // tipo de lead
        } else {
          if (value == "Quero saber mais") {
            $("input[name='input_13']").val("F76"); // form type
            $("input[name='input_14']").val("TP10"); // tipo de lead
          }
        }
      }
    }
  }
})(jQuery); // Fully reference jQuery after this point.
