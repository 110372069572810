(function ($) {
  /* ABOUT BLOCK */
  if ($(".block-about").length > 0) {
    var i = 1;
    $(".block-about").each(function () {
      $(this).addClass("aboutBlock_" + i);
      const aboutSwiper = new Swiper(
        ".aboutBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".aboutBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            769: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          },
        }
      );
      i++;
    });

    /*if ($(window).width() > 1023) {
      window.addEventListener("scroll", function () {
        const services = document.querySelector(".services");
        const servicesRect = services.getBoundingClientRect();

        if (servicesRect.top <= 0) {
          services.classList.add("fixed");
        } else {
          services.classList.remove("fixed");
        }
      });

      var title = document.getElementById("title");
      var services = document.querySelector(".services");

      window.onscroll = function () {
        var scrollPos = window.scrollY;
        var servicesTop = services.offsetTop;

        if (scrollPos >= servicesTop) {
          title.style.visibility = "visible";
          title.style.opacity = 1;
          var amountScrolled = scrollPos - servicesTop;
          var maxTranslation = 50;
          var translation = Math.min(amountScrolled / 2, maxTranslation);
          title.style.transform = "translateY(-" + translation + "px)";
        }
      };
    }*/
  } 

  /* BLUE ENERGY BLOCK */
  if ($(".block-energy").length > 0) {
    var i = 1;
    $(".block-energy").each(function () {
      $(this).addClass("energyBlock_" + i);
      const energySwiper = new Swiper(
        ".energyBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".energyBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 2.4,
              spaceBetween: 10,
            },
          },
        }
      );
      i++;
    });
  }

  /* BLUE SERVICES BLOCK */
  if ($(".block-services").length > 0) {
    var i = 1;
    $(".block-services").each(function () {
      $(this).addClass("servicesBlock_" + i);

      /*const servicesSwiper = new Swiper(
        ".servicesBlock_" + i + " .swiper-container",
        { 
          watchOverflow: true,
          pagination: {
            el: ".servicesBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            320: {
              autoHeight: true,
              slidesPerView: 1,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          },
        }
      );*/

      $(".services-btn").click(function() {
        var id = $(this).data("service");
        $(".services-btn").removeClass("active");
        $(this).addClass("active");
        $(".service-wrapper").hide();
        $("#service_"+id).fadeIn();
      });

      i++;
    });
  }

  /* ADVANTAGES BLOCK */
  if ($(".block-advantages").length > 0) {
    var i = 1;
    $(".block-advantages").each(function () {
      $(this).addClass("advantagesBlock_" + i);
      var columns = $(".advantagesBlock_" + i + " .swiper-container").data(
        "columns"
      );

      const advantagesSwiper = new Swiper(
        ".advantagesBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".advantagesBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: columns,
              spaceBetween: 15,
            },
          },
        }
      );
      i++;
    });
  }

  /* SIMULATOR BLOCK */
  if ($(".block-simulator").length > 0) {
    var i = 1;
    $(".block-simulator").each(function () {
      $(this).addClass("simulatorBlock_" + i);
      var curr_id = i;

      $(".simulatorBlock_" + curr_id + " .questions .options label").click(
        function () {
          var question = $(this).data("question");
          var answer = $(this).data("answer");

          if (question) {
            $("#" + question + " .options").each(function() {
              $(this).find("label").addClass("is-from-sub-question");            
            });
            $("#" + question).addClass("is-sub-question").fadeIn();
            $("html, body").stop().animate({scrollTop: $(".is-sub-question").offset().top - 200}, 500, 'swing');  
          } else {
            if(!$(this).hasClass("is-from-sub-question")) {
              $(".simulatorBlock_" + curr_id + " .is-sub-question").hide();
              $(".simulatorBlock_" + curr_id + " .is-sub-question .options input").each(function() {
                $(this).prop("checked", false);
              });
              $(this).removeClass("is-from-sub-question");
            }
          }

          if (answer) {
            $("html, body").animate({
              scrollTop: $("#" + answer).offset().top,
            });
          }
        }
      );

      /*$(".simulatorBlock_" + i + " .questions .go-back").click(function () {
        $(
          ".simulatorBlock_" +
            i +
            " .questions .options input[type='radio']:checked"
        ).prop("checked", false);
        $(".questions:not(':eq(0)')").hide();
        $(".questions:eq(0)").fadeIn();
      });*/

      i++;
    });
  }

  /* REGISTER BLOCK */
  if ($(".block-register").length > 0) {
    var i = 1;
    $(".block-register").each(function () {
      $(this).addClass("registerBlock_" + i);

      const registerSwiper = new Swiper(
        ".registerBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".registerBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".registerBlock_" + i + " .swiper-button-next",
            prevEl: ".registerBlock_" + i + " .swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 15,
            },
          },
          on: {
            slideChangeTransitionStart: function (swiper) {
              $(".item-wrapper").hide(0);
              $(".item-wrapper")
                .removeClass("aos-init")
                .removeClass("aos-animate");
              var d = 0;
              for (
                var i = swiper.activeIndex;
                i < swiper.activeIndex + swiper.params.slidesPerGroup;
                i++
              ) {
                var delay = 500 * d;
                $(".item-wrapper:eq(" + i + ")").attr("data-aos-delay", delay);
                d++;
              }
            },
            slideChangeTransitionEnd: function () {
              $(".item-wrapper").show(0);
              AOS.init();
            },
          },
        }
      );
      i++;
    });
  }

  /* APP BLOCK */
  if ($(".block-app").length > 0) {
    var i = 1;
    $(".block-app").each(function () {
      $(this).addClass("appBlock_" + i);

      const appSwiper = new Swiper(".appBlock_" + i + " .swiper-container", {
        watchOverflow: true,
        pagination: {
          el: ".appBlock_" + i + " .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 24,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 40,
          },
          992: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 50,
          },
        },
      });
      i++;
    });
  }

  /* HIGHLIGHTS BLOCK */
  if ($(".block-highlights").length > 0) {
    var i = 1;
    $(".block-highlights").each(function () {
      $(this).addClass("highlightsBlock_" + i);
      const highlightsSwiper = new Swiper(
        ".highlightsBlock_" + i + " .swiper-container",
        {
          watchOverflow: true,
          pagination: {
            el: ".highlightsBlock_" + i + " .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            769: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          },
        }
      );
      i++;
    });
  }
})(jQuery);
